import React, { ReactNode, useLayoutEffect } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "./Layout";
import formatPublishedDate from "./formatPublishedDate";
import generatePageTitle from "./components/Page/generatePageTitle";

export const query = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        description
        published
        hasTikTokEmbeds
      }
      content: body
    }
  }
`;

type EpisodeProps = {
  location: {
    pathname: string;
  };
  data: {
    mdx: {
      frontmatter: {
        title: string;
        description: string;
        published: string;
        hasTikTokEmbeds: boolean | null;
      };
      content: string;
    };
  };
};

const EpisodeContentContainer = styled.main`
  padding: 1em 2em;
  margin: auto;
  max-width: 50rem;
  p {
    line-height: 1.8em;
  }
  a {
    color: ${(props) => props.theme.colors.accents2};
    &:visited {
      color: ${(props) => props.theme.colors.accents2};
    }
  }
`;

const EpisodeTitle = styled.h2`
  color: ${(props) => props.theme.colors.text};
`;

const EpisodeDate = styled.h4`
  margin: 0;
`;

const TimelineSection = styled.section`
  thead {
    display: none;
  }
  tr {
    td {
      padding: 2rem;
      padding-left: 0;
      :first-of-type {
        color: ${(props) => props.theme.colors.accents2};
        text-align: right;
      }
    }
  }
`;

type TimelineProps = {
  children: ReactNode;
};

const Timeline = ({ children }: TimelineProps) => {
  return (
    <TimelineSection>
      <h3>Timeline</h3>
      {children}
    </TimelineSection>
  );
};

const EmbedContainer = styled.div`
  margin: 4rem 0;
  width: 100%;
  height: 170px;
  border-radius: 10px;
  overflow: hidden;
`;

const EmbedFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

type EmbeddedEpisodeProps = {
  url: string;
};

const EmbeddedEpisode = ({ url }: EmbeddedEpisodeProps) => {
  return (
    <EmbedContainer>
      <EmbedFrame
        allow="autoplay"
        scrolling="no"
        seamless
        src={url}
      ></EmbedFrame>
    </EmbedContainer>
  );
};

const StyledTikTokEmbed = styled.div`
  margin-top: 4rem;
  blockquote {
    max-width: 605px;
    min-width: 325px;
  }
`;

type TikTokEmbedProps = {
  header?: string;
  url: string;
  videoId: string;
  children: ReactNode;
};
const TikTokEmbed = ({ header, url, videoId, children }: TikTokEmbedProps) => {
  return (
    <StyledTikTokEmbed>
      {header && <h3>{header}</h3>}
      <blockquote className="tiktok-embed" cite={url} data-video-id={videoId}>
        {children}
      </blockquote>
    </StyledTikTokEmbed>
  );
};

const shortcodes = {
  EmbeddedEpisode,
  TikTokEmbed,
  Timeline,
};

const Episode = ({ data, location }: EpisodeProps) => {
  const episode = data.mdx.frontmatter;
  const episodeContent = data.mdx.content;
  const title = `${generatePageTitle("Episode")} | ${episode.title}`;
  useLayoutEffect(() => {
    if (episode.hasTikTokEmbeds) {
      const tikTokEmbed = document.createElement("script");
      tikTokEmbed.setAttribute("async", "");
      tikTokEmbed.setAttribute("src", "https://www.tiktok.com/embed.js");
      document.body.appendChild(tikTokEmbed);
    }
  }, [episode]);

  return (
    <>
      <Helmet title={title} />

      <Layout
        title={episode.title}
        path={location.pathname}
        description={episode.description}
      >
        <EpisodeContentContainer>
          <EpisodeTitle>{episode.title}</EpisodeTitle>
          <EpisodeDate>{formatPublishedDate(episode.published)}</EpisodeDate>
          <MDXProvider components={shortcodes}>
            <MDXRenderer>{episodeContent}</MDXRenderer>
          </MDXProvider>
        </EpisodeContentContainer>
      </Layout>
    </>
  );
};

export default Episode;
